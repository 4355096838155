import axios from 'axios'

import { refreshToken } from '../services/refreshToken'
import { logout, redirectToDefaultModulo } from '../services/session'

import { bearerTokenHeader, empresaToken } from './authorizationHeader'

import { getUrl } from './config'
import { getAuth, getRouter, getDefaultModulo } from '../contexts/global'
import InvalidSession from '../errors/InvalidSession'

const api = axios.create({
  baseURL: getUrl()
})

api.interceptors.request.use(requestBefore)
api.interceptors.response.use(undefined, responseError)

const refreshTokenFn = refreshToken()

async function requestBefore(config) {
  const token = window.localStorage.getItem('wisepay-token')
  const tokenEmpresa = window.localStorage.getItem('wisepay-empresa')
  let bearer = {}
  let empresa = {}
  if (token) {
    const currentToken = await refreshTokenFn(token)
    if (!currentToken) {
      logout({
        auth: getAuth(),
        history: getRouter(),
        invalidSession: true
      })
      throw new InvalidSession()
    }
    bearer = bearerTokenHeader(token)
  }

  if (tokenEmpresa) empresa = empresaToken(tokenEmpresa)
  return {
    ...config,
    headers: {
      ...config.headers,
      ...bearer,
      ...empresa,
      'type-application': 'web'
    }
  }
}

function responseError(error) {
  if (error.response && error.response.config.url !== 'login') {
    if (error.response.status === 401) {
      logout({
        auth: getAuth(),
        history: getRouter(),
        invalidSession: true
      })

      return axios.interceptors.response.eject()
    }

    if (error.response.status === 403) {
      redirectToDefaultModulo({
        defaultModulo: getDefaultModulo(),
        history: getRouter()
      })
      return axios.interceptors.response.eject()
    }
  }

  return Promise.reject(error)
}

// function getErrorMessage (error) {
//   if (
//     error.response &&
//     error.response.data &&
//     typeof error.response.data.error === 'string'
//   ) {
//     return error.response.data.error
//   }
//   return null
// }

export default api
