import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import { datadogRum } from '@datadog/browser-rum'
import { getUrl } from '../utils/config'

const ThemeContext = createContext()

function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(null)

  useEffect(() => {
    async function loadTheme() {
      try {
        const result = await axios.get(`${getUrl()}users/theme`)

        if (result.data.data.theme) {
          setTheme({
            managedWisepay: result.data.data.managedWisepay,
            twoFactorRequired: result.data.data.twoFactorRequired,
            whitelabelSocialNetworks: result.data.data.whitelabelSocialNetworks,
            whitelabelIdentityVerifications: result.data.data.whitelabelIdentityVerifications,
            ...result.data.data.theme
          })

          setFavicon(result.data.data.theme)
          setTitle(result.data.data.theme)
          saveTheme(result.data.data.theme)
          datadogRum.setGlobalContext({
            Whitelabel: result.data.data.theme.whitelabel_name,
          });
        } else {
          const defaultTheme = {
            'image-background-login': 'https://assetswp.sfo2.digitaloceanspaces.com/WL/wisepay/bg-login.jpg',
            'logo-color': 'https://assetswp.sfo2.digitaloceanspaces.com/WL/wisepay/logo-color.png',
            'logo-white': 'https://assetswp.sfo2.digitaloceanspaces.com/WL/wisepay/logo-white.png',
            'logo-title-white': 'https://assetswp.sfo2.digitaloceanspaces.com/WL/wisepay/logo-title-white.png',
            'logo-icon-white': 'https://assetswp.sfo2.digitaloceanspaces.com/WL/wisepay/logo-icon-white.png',
            favicon: 'https://assetswp.sfo2.digitaloceanspaces.com/WL/wisepay/favicon.png',
            whitelabel_name: 'WisePay',
            'frase-login': 'A melhor solução para o seu <strong>negócio</strong>',
            'gif-loading': 'https://assetswp.sfo2.digitaloceanspaces.com/WL/wisepay/loading.gif',
            'menu-color': {
              'color-init': '#0385c2',
              'color-end': '#052271'
            },
            'color-primary': '#F47B0E',
            'color-primary-disabled': '#FFA95C',
            'color-secondary': '#0384C1',
            'chat-color': '#68c102'
          }
          setTheme(defaultTheme)
          setFavicon(defaultTheme)
          setTitle(defaultTheme)
          saveTheme(defaultTheme)
        }
      } catch (error) {
        // show error
      }
    }

    function setFavicon(result) {
      window.document.querySelector("link[rel*='icon']").setAttribute('href', result.favicon)
    }

    function setTitle(result) {
      window.document.querySelector("title").innerHTML = result.whitelabel_name
    }

    function saveTheme(theme) {
      window.localStorage.setItem('whitelabel-theme', JSON.stringify(theme))
    }

    loadTheme()
  }, [])

  if (theme === null) {
    return null
  }

  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
