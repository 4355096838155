import React, { useContext, useEffect, useState, createContext } from 'react'
import { useLocation } from 'react-router-dom'

import api from '../utils/api'
import { storeDefaultModulo } from './global'
import { AuthContext } from './auth'
import { EmpresaContext } from './empresa'

const ModulosContext = createContext()

function ModulosProvider({ children }) {
  const location = useLocation()
  const auth = useContext(AuthContext)
  const { empresaSelected } = useContext(EmpresaContext)
  const [modulos, setModulos] = useState([])
  const [defaultModulo, setDefaultModulo] = useState()

  useEffect(() => {
    checkToLoadModulos()
  }, [])

  useEffect(() => {
    checkToLoadModulos()
  }, [location.pathname, auth.userInfo.isAuth])

  function checkToLoadModulos() {
    if (location.pathname !== '/login' && auth.userInfo.isAuth) {
      loadModulos()
    }
  }

  async function loadModulos() {
    try {
      const result = await api.get('modulos/access')
      const modulos = result.data.data.modulos
      let moduloD = ''

      if (empresaSelected.root && auth.userInfo.user.root) {
        moduloD = '/dashboard'
      } else {
        modulos.forEach(modulo => {
          if (modulo.moduloFuncoes.length > 0) {
            modulo.moduloFuncoes.forEach(mf => {
              if (mf.path === '/extrato/transacoes') {
                moduloD = '/extrato/transacoes'
              } else if (moduloD === '' && mf.path) {
                moduloD = mf.path
              }
            })
          }
        })
      }

      setModulos(modulos)
      setDefaultModulo(moduloD)
      storeDefaultModulo(moduloD)
    } catch (error) {
      // show error
    }
  }

  return (
    <ModulosContext.Provider
      value={{
        modulos,
        defaultModulo
      }}
    >
      {children}
    </ModulosContext.Provider>
  )
}

export { ModulosProvider, ModulosContext }
